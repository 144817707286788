/** @format */

import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import { HTMLContent } from "../components/Content"
import ImageInset from "../components/ImageInset"
import HyperLinkText from "../components/HyperLinkText"
import ImageCompareSlider from "../components/ImageCompareSlider"
import { Tab, Tabs } from "@material-ui/core"

// eslint-disable-next-line
export const BackgroundPageTemplate = ({ title, cityFlags }) => {
  const [flagSlider, setFlagSlider] = React.useState({
    imgSrcOne: cityFlags[0].imageOne,
    imgSrcTwo: cityFlags[0].imageTwo,
    imgCityName: cityFlags[0].city,
  })

  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setFlagSlider({
      imgSrcOne: cityFlags[newValue].imageOne,
      imgSrcTwo: cityFlags[newValue].imageTwo,
      imgCityName: cityFlags[newValue].city,
    })

    setValue(newValue)
  }
  return (
    <section className="section">
      <div className="container">
        <div className="columns is-multiline is-centered">
          <div className="column is-8 is-6-desktop">
            <h2 className="mb-5 is-size-1 is-size-3-mobile has-text-weight-bold">
              {title}
            </h2>
            <p className="subtitle has-text-grey mb-5">
              Did you know that Raleigh{" "}
              <HyperLinkText
                link={
                  "https://en.wikipedia.org/wiki/Flag_of_Raleigh,_North_Carolina"
                }
                text={"has a flag"}
              />
              ? Adopted in 1960, the historic flag is a double-sided triband flag bearing the city seal on one side and Sir Walter Raleigh’s coat of arms with a crest and motto on the other. The flag's design is based on a piece of artwork made as a gift for the USS <em>Raleigh</em> warship in 1899.​
            </p>
          </div>
          <ImageInset
            figureText={"Raleigh Flag of 1960​​​"}
            images={[
              "/img/background-1899-obverse.png",
              "/img/background-1899-reverse.png",
            ]}
          />
          <div className="column is-8 is-6-desktop">
            <p className="subtitle has-text-grey mb-5">
              Sadly, you won’t see many instances of the historic Raleigh flag
              flying around the city. Many citizens don’t even know it exists.
              We think this is because the historic flag isn’t especially
              memorable, or even easy to recognize. It doesn't follow the
              principles of{" "}
              <HyperLinkText
                link={"/design-principles"}
                text={"good flag design"}
              />
              . And indeed, its designers didn't intend for it to be adopted as
              an official city flag. Rather, they created it as a hand-painted
              piece of art meant as a one-time gift. This flag wasn't designed
              to fulfill the main function of a flag: to signal identity at a
              distance. It isn't fit for purpose because it wasn't designed for
              purpose.
            </p>
          </div>
          <div className="column is-10 is-8-desktop mb-5">
            <div className="box has-background-light">
              <div className="is-vcentered columns is-multiline">
                <div className="column is-6">
                  <div className="iframe-container">
                    <iframe
                      width="853"
                      height="480"
                      className="image is-fullwidth"
                      style={{ height: "208px", objectFit: "cover" }}
                      src="https://www.youtube.com/embed/pnv5iKB2hl4"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
                <div className="column is-6">
                  <p className="is-size-5">
                    Many cities lack well-designed flags.  See podcaster and radio host Roman Mars explain how
                    most city flags don’t follow good design principles in this
                    humorous and informative TED talk.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-8 is-6-desktop">
            <p className="subtitle has-text-grey mb-5">
              Contrast the cluttered design of the historic Raleigh flag with
              the crisp, compelling imagery in the flags of other cities such as{" "}
              <HyperLinkText
                link={"https://en.wikipedia.org/wiki/Flag_of_Chicago"}
                text={"Chicago"}
              />
              ,{" "}
              <HyperLinkText
                link={"https://en.wikipedia.org/wiki/Flag_of_Denver"}
                text={"Denver"}
              />
              , or{" "}
              <HyperLinkText
                link={"https://en.wikipedia.org/wiki/Flag_of_Washington,_D.C."}
                text={"Washington, DC"}
              />
              . These flags are so effective and well-loved that you see them
              everywhere in their home cities. Not only do local residents and
              businesses fly these city flags, but you can see the flags
              depicted on souvenirs, signs, clothing – you name it. Some proud
              citizens of Chicago even have{" "}
              <HyperLinkText
                link={
                  "https://www.google.com/search?q=chicago+flag+tattoo&rlz=1C1GCEB_enUS889US889&sxsrf=AOaemvKJ0NF0y9crF8VtAV0TnpcOOl1KaA:1634514217647&source=lnms&tbm=isch&sa=X&sqi=2&ved=2ahUKEwiUtsr6z9LzAhWQSPEDHRXpAWYQ_AUoAXoECAEQAw&biw=1280&bih=609&dpr=1.5"
                }
                text={"tattoos"}
              />{" "}
              of the Chicago flag!
            </p>
          </div>
          <ImageInset
            figureText={"Adaptations of some well-loved city flags​"}
            images={[
              "/img/background-chicago-pin.png",
              "/img/background-denver-cap.png",
              "/img/background-dc-pin.png",
            ]}
          />
          <div className="column is-8 is-6-desktop">
            <p className="subtitle has-text-grey">
              In fact you need look no further than our neighboring cities to
              find some simple, effective flags. Note the clean, distinctive
              designs of the flags of Durham and Charlotte, adopted in the
              1980s. You’ll find these flags all over our fellow Carolina
              cities. They're a source of civic pride.
            </p>
          </div>
          <ImageInset
            figureText={"Flags of Durham & Charlotte, NC​​"}
            images={[
              "/img/background-durham.png",
              "/img/background-charlotte.png",
            ]}
          />
          <div className="column is-8 is-6-desktop">
            <p className="subtitle has-text-grey">
              As awareness of flag design principles has spread in recent years,
              many municipalities have undergone movements to redesign their
              flags. Cities have recognized that a handsome, emblematic flag is
              a standard under which the citizens of a town can rally. See
              examples of some updated city flags here:
            </p>
          </div>
          <div className="container">
            <div className="columns is-multiline is-centered">
              <div className="column is-8 has-text-centered">
                <div className="card has-background-light">
                  {cityFlags.map((cityFlag, index) => (
                    <div
                      role="tabpanel"
                      hidden={value !== index}
                      id={`scrollable-force-tabpanel-${index}`}
                      aria-labelledby={`scrollable-force-tab-${index}`}
                    >
                      {value === index && (
                        <div className="columns is-centered is-multiline">
                          <div className="column is-8">
                            <p className="subtitle">
                              Use slider to compare old flag (left) vs new
                              (right)
                            </p>
                            <ImageCompareSlider
                              imgSrcOne={flagSlider.imgSrcOne}
                              imgSrcTwo={flagSlider.imgSrcTwo}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    centered
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                  >
                    {cityFlags.map((cityFlag) => (
                      <Tab label={cityFlag.city} />
                    ))}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>

          <div className="column is-8 is-6-desktop">
            <p className="subtitle has-text-grey mb-5">
              The city of Raleigh deserves an iconic flag. While the flag of
              1960 will always be an endearing piece of history, a new,
              well-designed flag can be a highly recognizable symbol that
              provides our citizenry with a sense of common identity. The new
              Raleigh flag campaign seeks to establish that symbol and make it
              official.​
            </p>
            <p className="subtitle has-text-grey mb-5">
              Join the effort and help{" "}
              <HyperLinkText
                link={"/get-involved"}
                text={"promote the new flag"}
              />
              . This grassroots movement will continue to spread awareness and
              entreat the Raleigh city council to adopt the new Raleigh flag as
              an official flag of the city.​
            </p>
          </div>
        </div>
        <div className="columns is-multiline is-centered">
          <div className="column is-8 is-6-desktop">
            <Link className="button is-primary" to="/the-new-flag">
              Learn about the new flag​
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

BackgroundPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  cityFlags: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      imageOne: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      imageTwo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    })
  ),
}

const BackgroundPage = ({ data }) => {
  const { markdownRemark: post } = data
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <BackgroundPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        cityFlags={frontmatter.cityFlags}
      />
    </Layout>
  )
}

BackgroundPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default BackgroundPage

export const BackgroundPageQuery = graphql`
  query BackgroundPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        cityFlags {
          city
          imageOne {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
          imageTwo {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
